import React, { useEffect, useState } from 'react';
import { getEnvSysCmsAll } from '../../Api/EnvSysCmsApi';
import LoadingBar from '../../Components/LoadingBar';
import EnvValueSetting from './EnvValueSetting';

const mutating = false;

function Index() {
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [EnvSysCmsData, setEnvSysCmsData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    getEnvSysCmsAll()
      .then((res) => {
        const { data } = res;
        setEnvSysCmsData(data.data);
      })
      .catch((err) => {
        console.error(err);
      });
    setLoading(false);
  };

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <EnvValueSetting
        loading={loading}
        data={EnvSysCmsData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        refetchFn={fetchData}
      />
    </div>
  );
}

export default Index;
