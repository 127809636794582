import { createAction } from 'redux-actions';
import ScheduleChat from '../Types/scheduleChat';

export const loadScheduleChat = createAction(ScheduleChat.LOAD_SCHEDULE_CHAT);
export const loadScheduleChatSuccess = createAction(
  ScheduleChat.LOAD_SCHEDULE_CHAT_SUCCESS,
);
export const loadScheduleChatFail = createAction(
  ScheduleChat.LOAD_SCHEDULE_CHAT_FAIL,
);
export const estimateScheduleChatReceivers = createAction(
  ScheduleChat.ESTIMATE_SCHEDLULE_CHAT_RECEIVERS,
);
export const estimateScheduleChatReceiversSuccess = createAction(
  ScheduleChat.ESTIMATE_SCHEDLULE_CHAT_RECEIVERS_SUCCESS,
);
export const estimateScheduleChatReceiversFail = createAction(
  ScheduleChat.ESTIMATE_SCHEDLULE_CHAT_RECEIVERS_FAIL,
);
