const ScheduleChat = {
  LOAD_SCHEDULE_CHAT: 'LOAD_SCHEDULE_CHAT',
  LOAD_SCHEDULE_CHAT_SUCCESS: 'LOAD_SCHEDULE_CHAT_SUCCESS',
  LOAD_SCHEDULE_CHAT_FAIL: 'LOAD_SCHEDULE_CHAT_FAIL',
  ESTIMATE_SCHEDLULE_CHAT_RECEIVERS: 'ESTIMATE_SCHEDLULE_CHAT_RECEIVERS',
  ESTIMATE_SCHEDLULE_CHAT_RECEIVERS_SUCCESS:
    'ESTIMATE_SCHEDLULE_CHAT_RECEIVERS_SUCCESS',
  ESTIMATE_SCHEDLULE_CHAT_RECEIVERS_FAIL:
    'ESTIMATE_SCHEDLULE_CHAT_RECEIVERS_FAIL',
};

export default ScheduleChat;
