import { call, put, takeLatest } from 'redux-saga/effects';
import {
  estimateScheduleChatReceiversFail,
  estimateScheduleChatReceiversSuccess,
  loadScheduleChatFail,
  loadScheduleChatSuccess,
} from '../Actions/scheduleChat';
import ScheduleChat from '../Types/scheduleChat';
import { getAllScheduleChat } from 'Api/scheduleAPI';

function* handleLoadScheduleChat(action) {
  try {
    const res = yield call(getAllScheduleChat, action.payload);
    const { data } = res;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadScheduleChatSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadScheduleChatFail(error));
  }
}

function* handleEstimatingScheduleChatReceivers(action) {
  try {
    const res = yield call(getAllScheduleChat, action.payload);
    const { data } = res;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(estimateScheduleChatReceiversSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(estimateScheduleChatReceiversFail(error));
  }
}

export default function* scheduleChatSaga() {
  yield takeLatest(ScheduleChat.LOAD_SCHEDULE_CHAT, handleLoadScheduleChat);
  yield takeLatest(
    ScheduleChat.ESTIMATE_SCHEDLULE_CHAT_RECEIVERS,
    handleEstimatingScheduleChatReceivers,
  );
}
