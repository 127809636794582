import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import { getUser, sendSmsUser, setTimeSms } from '../../../Api/userAPI';
import { MuiThemeProvider } from '@material-ui/core';
import { Card, Col, Label, Row } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import RadioGroup from '../../../Components/RadioGroup';
import {
  CONVERSION_TAG_URL,
  DISPLAY_OPTIONS,
  GENDER,
  TIMER_SENDING_TYPE,
} from '../../../Common/constant';
import { conversionTags } from '../../../Api/conversionTagApi';
import Util from '../../../Util/Util';
import { style } from '../common-styles';
import { themeStyleNormal1Modal } from '../theme_modal';
import { TEXT_HTML_SEND_SMS_MODAL } from '../../../Common/modal_text_info';
import {
  convertDisplayOptionsText,
  displayOptionsOptionsRequired,
} from '../../../Util/utils';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { CODE_MAP_DEFINED_API } from '../../../Config/listConstants';
import { previewTimerSms } from 'Api/timerAPI';

function SendSmsModal(props) {
  const {
    visible,
    onClose,
    userId,
    nickName,
    avatar,
    phoneNumber,
    password,
    depositpoint,
    envInfoTag,
    needOtp,
    labelDb,
  } = props;
  const [data, setData] = useState();
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [hourSend, setHourSend] = useState(moment().format('HH').toString());
  const [minusSend, setMinusSend] = useState(moment().format('mm').toString());
  const [dateSend, setDateSend] = useState(
    moment(new Date(moment().startOf('day')).getTime()).format('YYYY-MM-DD'),
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date(moment().startOf('day')).getTime(),
  );
  const [timeSend, setTimeSend] = useState();
  const [timerSms, setTimerSms] = useState(`${DISPLAY_OPTIONS.OFF}`);

  const [conversionTag, setConversionTag] = useState([]);
  const [contentPreview, setContentPreview] = useState(null);

  useEffect(() => {
    conversionTags({}).then((res) => {
      const { data: tags } = res;
      setConversionTag(tags?.data || []);
    });
  }, []);

  const handleSubmit = async () => {
    if (data) {
      if (String(timerSms) && +timerSms === DISPLAY_OPTIONS.OFF) {
        await sendSmsUser({ id: userId, data }).then((response) => {
          const { data } = response;
          if (data.success) {
            onClose();
            toast.success(TOAST_MESSAGE.SUCCESS_ADD);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_ADD);
            setConFirmVisible(false);
          }
        });
      } else {
        await setTimeSms({
          sendTime: timeSend,
          userIds: [userId],
          type: TIMER_SENDING_TYPE.FROM_USER_DETAIL,
          content: data,
        }).then((response) => {
          const { data } = response;
          if (data.success) {
            onClose();
            toast.success(TOAST_MESSAGE.SUCCESS_ADD);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_ADD);
            setConFirmVisible(false);
          }
        });
      }
    }
  };

  const onChange = (event) => {
    const value = event.target.value.trimLeft().trimRight();
    setData(value);
  };

  const handleConfirm = async () => {
    if (!data) {
      return toast.error('本文してください!');
    }

    const startDay = new Date(moment().startOf('day')).getTime();
    const checkTime = timeSend || startDay;
    if (
      String(timerSms) &&
      +timerSms === DISPLAY_OPTIONS.ON &&
      checkTime &&
      checkTime < new Date().getTime()
    ) {
      return toast.error('予約時間が過去に設定されています');
    }

    replaceUrlPreview(data);
  };

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  let minute = [];
  for (let index = 0; index < 60; index++) {
    if (index < 10) {
      minute.push('0' + index);
    } else {
      minute.push(index);
    }
  }

  const handleDate = (date) => {
    setSelectedDate(date);
    date = moment(date).format('YYYY-MM-DD');
    setDateSend(date);
    let sendTime = new Date(`${date} ${hourSend}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleMinus = (e) => {
    let value = e.target.value;
    setMinusSend(value);
    let sendTime = new Date(`${dateSend} ${hourSend}:${value}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourSend(value);
    let sendTime = new Date(`${dateSend} ${value}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const replaceUrlPreview = async () => {
    const { data: replaceContent } = await previewTimerSms({ content: data });
    const htmlReplacedContent = `${replaceContent.data}`.replace(
      /(\r\n|\r|\n)/g,
      '<br>',
    );
    setContentPreview(htmlReplacedContent);
    setConFirmVisible(true);
  };

  const handlerTimerSms = (value) => {
    setTimerSms(`${value}`);
  };

  return (
    <div style={{ position: 'relative' }}>
      <>
        {confirmVisible && (
          <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={confirmVisible}
            onClose={onCloseConfirm}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle
              id="max-width-dialog-title"
              className={'d-title-modal-custom'}
            />
            <Button
              style={{
                position: 'absolute',
                top: 8,
                right: 0,
              }}
              onClick={onCloseConfirm}
            >
              <CloseIcon color="primary" />
            </Button>

            <DialogContent>
              <div style={{ margin: '0.6rem 0 1rem 0' }}>
                {/*実行してよろしいですか？*/}
                <Card body className="mb-4">
                  <Row>
                    <Col
                      style={style}
                      xs={6}
                      className={
                        'd-line-content d-flex d-justify-space-between'
                      }
                    >
                      <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <p style={{ paddingLeft: 3 }}>日時</p>
                      </div>
                      <div
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          textAlign: 'end',
                        }}
                        className={'mr-1'}
                      >
                        <p>
                          {String(timerSms) &&
                          +timerSms === DISPLAY_OPTIONS.ON &&
                          timeSend
                            ? moment(timeSend).format('YYYY/MM/DD HH:mm')
                            : dateSend}
                        </p>
                      </div>
                    </Col>
                    <Col style={style} xs={6} className={'d-line-content'}>
                      <div
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          textAlign: 'end',
                        }}
                        className={'mr-1'}
                      >
                        <span
                          className={`${
                            String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON
                              ? 'd-red-color'
                              : 'd-blue-color'
                          }`}
                        >
                          {convertDisplayOptionsText(timerSms)}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row className={'d-line-col'}>
                    <Col style={style} xs={6} className={'d-line-content'}>
                      <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        &nbsp;
                      </div>
                    </Col>
                    <Col style={style} xs={6} className={'d-line-content'}>
                      <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        &nbsp;
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card body className="mb-4">
                  <Row>
                    <Label style={style} xs={3}>
                      <p style={{ paddingLeft: 3 }}>本文</p>
                    </Label>
                    <Label style={{ ...style, paddingLeft: 8 }} xs={9}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: contentPreview,
                        }}
                      ></div>
                    </Label>
                  </Row>
                </Card>
              </div>
              <div>
                <center style={{ margin: 15 }}>
                  <Button
                    className="btn-custom btn-red"
                    onClick={onCloseConfirm}
                    color="primary"
                  >
                    いいえ
                  </Button>
                  <Button
                    className="btn-custom btn-blue"
                    onClick={handleSubmit}
                    color="primary"
                  >
                    はい
                  </Button>
                </center>
              </div>
            </DialogContent>
          </Dialog>
        )}

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                marginRight: 50,
              }}
            >
              <p className={'mb-0'}>ユーザー詳細-個別SMS送信</p>
              <div style={{ display: 'flex' }}>
                <p
                  className={'mb-0'}
                  style={{ fontSize: '1rem', marginRight: 20 }}
                >
                  受信者
                </p>
                <img
                  className="object-fit-cover"
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    marginRight: 20,
                  }}
                  src={avatar ? avatar.url : NoPhoto}
                  alt=""
                />
                <p className={'mb-0'} style={{ fontSize: '1rem' }}>
                  {nickName}
                </p>
              </div>
            </div>
          </DialogTitle>
          <Button
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div
              className={'text mb-0'}
              dangerouslySetInnerHTML={{ __html: TEXT_HTML_SEND_SMS_MODAL }}
            ></div>
          </div>

          <DialogContent>
            <MuiThemeProvider theme={themeStyleNormal1Modal}>
              <Card style={{ marginBottom: 10 }}>
                <Row style={{ padding: 20 }}>
                  <Col xs={12}>
                    <div style={{ margin: '16px 0 8px 0' }}>
                      <span style={{ color: 'red' }}>*</span>送信文
                    </div>
                    <textarea
                      name="data"
                      rows="6"
                      onChange={onChange}
                      style={{ width: '100%', padding: 10 }}
                      maxLength={500}
                    />

                    <div className="mt-3 mb-1 timer-container">
                      <span>タイマー日時</span>
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="datePicker">
                        <DatePicker
                          selected={selectedDate}
                          className="date"
                          onChange={(e) => handleDate(e)}
                          locale="ja"
                          dateFormat="yyyy/MM/dd"
                          popperPlacement="top-start"
                          disabled={
                            !(
                              String(timerSms) &&
                              +timerSms === DISPLAY_OPTIONS.ON
                            )
                          }
                        />
                        <select
                          disabled={
                            !(
                              String(timerSms) &&
                              +timerSms === DISPLAY_OPTIONS.ON
                            )
                          }
                          defaultChecked={hourSend}
                          onChange={(e) => handleHour(e, 'start_hour')}
                          className="hour"
                        >
                          {hour.map((value) => (
                            <option
                              selected={value.toString() === hourSend}
                              key={value}
                              value={value}
                            >
                              {value}
                            </option>
                          ))}
                        </select>
                        <select
                          disabled={
                            !(
                              String(timerSms) &&
                              +timerSms === DISPLAY_OPTIONS.ON
                            )
                          }
                          onChange={(e) => handleMinus(e, 'start_minus')}
                          className="minus"
                        >
                          {minute.map((value) => (
                            <option
                              selected={value.toString() === minusSend}
                              key={value}
                              value={value}
                            >
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="display-setting-timer d-un-width">
                        <RadioGroup
                          name="displaySetting"
                          defaultValue={timerSms}
                          options={displayOptionsOptionsRequired()}
                          onChange={(e) => handlerTimerSms(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
              <div style={{ textAlign: 'right', marginBottom: 2 }}>
                <Button
                  onClick={handleConfirm}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  送信
                </Button>
              </div>
            </MuiThemeProvider>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
}

export default SendSmsModal;
