import { sendPost, sendDelete, sendPut, sendGet } from './axios';

export const updateScheduleMail = (data) =>
  sendPost('/cms/schedule-mail/update', data);
export const deleteScheduleMail = (id) =>
  sendDelete(`/cms/schedule-mail/${id}`);
export const updateScheduleSms = (id, data) =>
  sendPut(`/cms/schedule-sms/${id}`, data);
export const deleteScheduleSms = (id) => sendDelete(`/cms/schedule-sms/${id}`);

export const previewScheduleMail = (data) =>
  sendPost(`/cms/preview-content/schedule-mail`, data);
export const previewScheduleSms = (data) =>
  sendPost(`/cms/preview-content/schedule-sms`, data);

export const setScheduleChat = (data) =>
  sendPost(`/cms/set-schedule-chat`, data);
export const getAllScheduleChat = (data) =>
  sendGet(`/cms/get-schedule-chat`, data);
export const updateScheduleChat = (id, data) =>
  sendPut(`/cms/schedule-chat/${id}`, data);
export const deleteScheduleChat = (id) =>
  sendDelete(`/cms/schedule-chat/${id}`);
