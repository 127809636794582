import React, { useEffect, useState } from 'react';
import { Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { Card, Col, Label, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadDetailOperator,
  loadOperator,
} from '../../../../Redux/Actions/operator';
import LoadingBar from '../../../../Components/LoadingBar';
import RemoveModal from './RemoveModal';
import {
  DISPLAY_CMS_UI_TYPE,
  MEMBER_TYPE,
  REPLIED_STATUS,
  LOGIN_CONTROL,
  MEMBER_USER_STATUS,
} from '../../../../Common/constant';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NoPhoto from '../../../../assets/image/no_user_photo.png';
import Util from '../../../../Util/Util';
import Pagination from '../../../../Components/Pagination';
import { deleteOperator } from '../../../../Api/operatorAPI';
import { toast } from 'react-toastify';
import SortArrows from '../../../../Components/SortArrows';
import { TOAST_MESSAGE } from '../../../../Common/constant_text';
import { convertEnvInfo, convertStandByText } from '../../../../Util/utils';
import { LINK_MAP_DEFINED_API } from '../../../../Config/listConstants';
import UserDelete from '../../../../assets/image/delete_user.png';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const pageSize = 50;

function Index() {
  const params = useParams();
  const envUICms = useSelector((state) => state.envUICms.data);
  const admin = useSelector((state) => state.admin.data);
  const operatorId = params.operatorId;
  const dispatch = useDispatch();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [typeShowActionBtn, setTypeShowActionBtn] = useState({
    opeDetailWorkPage: DISPLAY_CMS_UI_TYPE.HIDDEN,
  }); // 0 show, 1 show + not link, 2 hidden

  const [filter, setFilter] = useState({
    sortBy: 'chara.lastlogin',
    orderBy: 'DESC',
    sort: { 'chara.lastlogin': 'DESC' },
  });

  const listChara = useSelector((state) => state.operator.data);
  const totalRow = useSelector((state) => state.operator.totalRow);
  const [pageIndex, setPageIndex] = useState(1);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [colorLabel, setColorLabel] = useState('');

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(
      convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1
        ?.content,
    );
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadOperator({ operatorId, pageIndex, pageSize, ...filter }));
  }, [dispatch, operatorId, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const deleteButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.OPERATOR_DETAIL_DELETE,
  );
  const sendMessageAsOperatorButton = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.ADMIN_OPE_SEARCH_USER_SMS_CHAT,
  );
  const operatorDetailWorkPage = envUICms.find(
    (item) => item.link === LINK_MAP_DEFINED_API.OPERATOR_DETAIL_WORK_PAGE,
  );

  const setAllShowAllForButton = () => {
    let tmp = {};
    Object.keys(typeShowActionBtn).forEach((key) => {
      tmp[key] = DISPLAY_CMS_UI_TYPE.ON;
    });
    setTypeShowActionBtn(tmp);
  };

  const accessTypes = [
    DISPLAY_CMS_UI_TYPE.ON,
    DISPLAY_CMS_UI_TYPE.HIDDEN,
    DISPLAY_CMS_UI_TYPE.NOT_LINK,
  ];

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemove = () => {
    if ((listChara || []).length > 0) {
      deleteOperator(operatorId)
        .then((response) => {
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
        })
        .catch((e) => {
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        });
    } else {
      setConfirmVisible(true);
    }
  };

  useEffect(() => {
    if (+admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      setAllShowAllForButton();
    }

    if (+admin?.memberType !== MEMBER_TYPE.SYSTEM_ADMIN) {
      let tmp = typeShowActionBtn;
      // Admin: Check type show setting
      if (
        operatorDetailWorkPage &&
        accessTypes.includes(+operatorDetailWorkPage?.type)
      ) {
        tmp.opeDetailWorkPage = +operatorDetailWorkPage?.type;
      }

      setTypeShowActionBtn(tmp);
    }
  }, [admin]);

  useEffect(() => {
    dispatch(loadDetailOperator(operatorId));
  }, [dispatch, operatorId]);

  const loading = useSelector((state) => state.detailOperator.loading);
  const operator = useSelector((state) => state.detailOperator.data);
  const saveLocalStore = (id) => {
    localStorage.setItem('operatorId', id);
  };

  const countNotReplie = (item) => {
    let total = 0;
    if (item.message.length > 0) {
      item.message.map((message) => {
        if (message.replied === REPLIED_STATUS.NOT_REPLIED) {
          total += 1;
        }
        return true;
      });
    }
    return total;
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (
      filter.sort[sortByKey] === undefined ||
      filter.sort[sortByKey] === 'DESC'
    ) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const styleTableCellTd = {
    padding: '0 .75rem',
  };

  return (
    <>
      {confirmVisible && (
        <RemoveModal
          visible={confirmVisible}
          onClose={handleClose}
          operatorId={operatorId}
        />
      )}
      <MuiThemeProvider theme={customTheme}>
        <LoadingBar show={loading} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 0',
          }}
        >
          <Link
            to={`/operator/salarypoint/${operatorId}`}
            target="_self"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              className="btn-blue"
              style={{
                margin: 'auto',
                width: 'auto',
                color: 'white',
              }}
              variant="contained"
            >
              給与ポイント
            </Button>
          </Link>

          <div>
            {operatorDetailWorkPage &&
              typeShowActionBtn?.opeDetailWorkPage !==
                DISPLAY_CMS_UI_TYPE.HIDDEN && (
                <Link
                  to={
                    typeShowActionBtn?.opeDetailWorkPage ===
                    DISPLAY_CMS_UI_TYPE.ON
                      ? `/admin_ope/work_page_individual/${operator?.id}`
                      : '#'
                  }
                  target="_self"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    marginRight: 15,
                  }}
                >
                  <Button
                    className="btn-blue"
                    style={{
                      margin: 'auto',
                      color: 'white',
                    }}
                    variant="contained"
                  >
                    {operatorDetailWorkPage?.content}
                  </Button>
                </Link>
              )}
            <Link
              to={`/operator/${operator?.id}/edit`}
              target="_self"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                className="btn-blue"
                style={{
                  margin: 'auto',
                  color: 'white',
                }}
                variant="contained"
              >
                編集
              </Button>
            </Link>
          </div>
        </div>
        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <Row>
                <Label style={style} xs={7}>
                  <p style={{ paddingLeft: 3, color: colorLabel }}>MySQL ID</p>
                  <hr style={stylehrleft} />
                </Label>
                <Label style={style} xs={5}>
                  {operator?.id}
                  <hr style={stylehrright} />
                </Label>
                <Label style={style} xs={7}>
                  <p style={{ paddingLeft: 3, color: colorLabel }}>
                    オペレーターNumber（重複NG）
                  </p>
                  <hr style={stylehrleft} />
                </Label>
                <Label style={style} xs={5}>
                  {operator?.operatorNumber}
                  <hr style={stylehrright} />
                </Label>
              </Row>
            </Card>
          </Col>
          <Col xs={8}>
            <Row>
              <Col xs={12}>
                <Card body className="mb-4">
                  <Row>
                    <Col xs={6}>
                      <Label style={style} xs={7}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>
                          電話番号（重複NG）
                        </p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={5}>
                        {operator?.phoneNumber}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={7}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>
                          パスワード（英数字8文字～10文字）
                        </p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={5}>
                        {operator?.password}
                        <hr style={stylehrright} />
                      </Label>
                      <Label style={style} xs={7}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>
                          オペレーター名
                        </p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={5}>
                        {operator?.operatorName}
                        <hr style={stylehrright} />
                      </Label>
                    </Col>
                    <Col xs={6}>
                      <Label style={style} xs={7}>
                        <p style={{ paddingLeft: 3, color: colorLabel }}>
                          ログイン制御
                        </p>
                        <hr style={stylehrleft} />
                      </Label>
                      <Label style={style} xs={5}>
                        {Util.loginControlToText(
                          Number(operator?.logincontrol),
                        )}
                        <hr style={stylehrright} />
                      </Label>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Card body className="mb-4">
              <Row>
                <Label style={style} xs={2}>
                  <p style={{ paddingLeft: 3, color: colorLabel }}>
                    管理者メモ
                  </p>
                </Label>
                <Label style={{ ...style, whiteSpace: 'pre-line' }} xs={10}>
                  {operator?.adminMemo}
                </Label>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card
              className="scroll-x-on-top"
              style={{ marginBottom: 20, marginTop: 20 }}
            >
              <Table padding="checkbox" className="table mb-0">
                <TableBody>
                  <TableRow>
                    <TableCell style={{ minWidth: 20, maxWidth: 20 }}>
                      No
                    </TableCell>
                    <TableCell align="center">画像</TableCell>
                    <TableCell style={{ minWidth: 70, maxWidth: 90 }}>
                      {labelDb?.label_nickName?.content}
                    </TableCell>
                    <TableCell>性別</TableCell>
                    <TableCell>オペレーターNumber</TableCell>
                    <TableCell>都道府県</TableCell>
                    <TableCell style={{ minWidth: 70, maxWidth: 70 }}>
                      給与ポイント
                    </TableCell>
                    <TableCell>送信数</TableCell>
                    <TableCell>受信数</TableCell>
                    <TableCell>未返信数</TableCell>
                    <TableCell style={{ minWidth: 30, maxWidth: 30 }}>
                      登録日
                    </TableCell>

                    <TableCell style={{ minWidth: 80, maxWidth: 80 }}>
                      <SortArrows
                        sortObject={filter.sort}
                        text="ログイン"
                        name="chara.lastlogin"
                        onClick={onSort}
                      />
                    </TableCell>

                    <TableCell>
                      <SortArrows
                        sortObject={filter.sort}
                        text="待機状態"
                        name="chara.standBy"
                        onClick={onSort}
                      />
                    </TableCell>
                    <TableCell align="center">作業</TableCell>
                  </TableRow>
                  {listChara?.map((chara, index) => (
                    <TableRow key={chara.id} style={{ height: 48 }}>
                      <TableCell
                        style={{
                          minWidth: 20,
                          maxWidth: 20,
                          ...styleTableCellTd,
                        }}
                      >
                        {(pageIndex - 1) * pageSize + index + 1}
                      </TableCell>
                      <TableCell style={styleTableCellTd} align="center">
                        <Link
                          to={`/user/${chara.id}/detail`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => saveLocalStore(chara.id)}
                        >
                          <img
                            className="object-fit-cover"
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: '50%',
                            }}
                            src={
                              chara?.memberStatus ===
                              MEMBER_USER_STATUS.IN_ACTIVE
                                ? UserDelete
                                : chara.avatar
                                  ? chara.avatar.url
                                  : NoPhoto
                            }
                            alt=""
                          />
                        </Link>
                      </TableCell>
                      <TableCell style={styleTableCellTd}>
                        {chara.nickName}
                      </TableCell>
                      <TableCell style={styleTableCellTd}>
                        {Util.genderIdToText(chara.gender)}
                      </TableCell>
                      <TableCell style={styleTableCellTd}>
                        {chara.operator.operatorNumber}
                      </TableCell>
                      <TableCell style={styleTableCellTd}>
                        {chara.states?.label}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: 70,
                          maxWidth: 70,
                          ...styleTableCellTd,
                        }}
                      >
                        {Util.separator(chara.salarypoint)}
                      </TableCell>
                      <TableCell style={styleTableCellTd}>
                        {chara.countTextSending}
                      </TableCell>
                      <TableCell style={styleTableCellTd}>
                        {chara.countTextReceived}
                      </TableCell>
                      <TableCell style={styleTableCellTd}>
                        {countNotReplie(chara)}
                      </TableCell>
                      <TableCell
                        className="text-muted"
                        style={{
                          minWidth: 50,
                          maxWidth: 50,
                          ...styleTableCellTd,
                        }}
                      >
                        <time>
                          {Util.timestampToDate(
                            chara.registerDate,
                            'YYYY/MM/DD',
                          )}
                        </time>
                      </TableCell>
                      <TableCell
                        className="text-muted"
                        style={{
                          minWidth: 80,
                          maxWidth: 80,
                          ...styleTableCellTd,
                        }}
                      >
                        <time>
                          {Util.timestampToDate(
                            chara.lastlogin,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </time>
                      </TableCell>
                      <TableCell style={styleTableCellTd}>
                        {convertStandByText(chara?.standBy)}
                      </TableCell>
                      <TableCell style={styleTableCellTd} align="center">
                        {(admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN ||
                          sendMessageAsOperatorButton.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) &&
                          (admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN ||
                          sendMessageAsOperatorButton.type !==
                            DISPLAY_CMS_UI_TYPE.NOT_LINK ? (
                            <Link
                              to={`/operator/detail/search_user?userId=${chara.id}`}
                              target="_self"
                              rel="noopener noreferrer"
                              className="d-color-link"
                            >
                              {sendMessageAsOperatorButton?.content}
                            </Link>
                          ) : (
                            sendMessageAsOperatorButton?.content
                          ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
            <center>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRow={totalRow}
                onPageChange={handlePageChange}
              />
            </center>
          </Col>
        </Row>
        {(admin?.memberType === 0 ||
          deleteButton.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
          <Button
            variant="contained"
            className="btn-custom btn-red"
            style={{ position: 'absolute', bottom: 20, right: 10 }}
            onClick={
              admin?.memberType === 0 ||
              deleteButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                ? handleRemove
                : ''
            }
          >
            {deleteButton.content}
          </Button>
        )}
      </MuiThemeProvider>
    </>
  );
}

export default Index;
