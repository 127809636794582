import React, { useMemo, useState } from 'react';
import { Button, FormLabel } from '@material-ui/core';
import { Col, FormGroup, Row } from 'reactstrap';
import RadioGroup from '../../../Components/RadioGroup';
import { COLUMN_DEFINED_SEARCH_BOX } from '../../../Common/constant';
import {
  scheduleSendStatusOptions,
  scheduleSendCategoryOptions,
} from 'Common/constant_text';
import RadioCheckboxGroupV2 from 'Components/V2/RadioCheckboxGroupV2';

const DEFAULT_CATEGORY_SET = new Set();
scheduleSendCategoryOptions.forEach((e) => {
  DEFAULT_CATEGORY_SET.add(`${e.value}`);
});

const DEFAULT_FILTER_STATE = {
  sendStatus: '',
  category: DEFAULT_CATEGORY_SET,
};

export default function ScheduleChatSearchBox(props) {
  const [filter, setFilter] = useState(DEFAULT_FILTER_STATE);

  const dispatchFilter = useMemo(() => {
    const newData = { ...filter, category: [...filter.category] };
    return newData;
  }, [filter]);

  const onChange = (event) => {
    event.persist();
    setFilter((filter) => ({
      ...filter,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeRadioCheckboxGroup = (name, checkedArr) => {
    setFilter((prev) => {
      const newData = { ...prev, [name]: checkedArr };
      return newData;
    });
  };

  const handleSearch = () => {
    try {
      return props.onSubmit(dispatchFilter);
    } catch (error) {
      alert(error);
    }
  };

  const handleCheckCurrentReceivers = () => {
    props.estimateReceivers();
  };

  const handleClear = () => {
    setFilter(DEFAULT_FILTER_STATE);
  };

  return (
    <React.Fragment>
      {/* <h4 className="page-title">チャット画像</h4> */}
      <div className="form__search" style={{ margin: '15px 15px' }}>
        <Row className="blockSearch">
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_1}></Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_2}></Col>
          <Col {...COLUMN_DEFINED_SEARCH_BOX.COLUMN_3}>
            <FormGroup row>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">配信設定</FormLabel>
                <RadioGroup
                  controlled
                  name="sendStatus"
                  defaultValue={filter.sendStatus}
                  options={[
                    { value: '', label: '全て' },
                    ...scheduleSendStatusOptions,
                  ]}
                  onChange={onChange}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12} className="d-item-form-wrapper">
                <FormLabel component="legend">配信カテゴリ</FormLabel>
                <RadioCheckboxGroupV2
                  name="category"
                  options={[...scheduleSendCategoryOptions]}
                  onChange={onChangeRadioCheckboxGroup}
                  value={filter.category}
                  classCustom="d-cus-item"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <div className="mtSearch" style={{ position: 'relative' }}>
          <center>
            <Button
              className="btn-search"
              variant="contained"
              onClick={handleSearch}
            >
              検索する
            </Button>
          </center>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <Button
              className="btn-blue mr-2"
              variant="contained"
              onClick={handleCheckCurrentReceivers}
              disabled={props.loadingReceivers}
            >
              送信数チェック
            </Button>
            <Button
              className="btn-red"
              variant="contained"
              onClick={handleClear}
            >
              クリア
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
