import {
  Button,
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import { updateEnvUI } from '../../../Redux/Actions/envUI';
import { pathDisplayPageAfterLogin } from '../../../Util/utils';

function EnvUiWebSettingList({ nameEnv = {}, isEditing, setIsEditing }) {
  const [envSetting, setEnvSetting] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setEnvSetting(nameEnv);
  }, [nameEnv]);

  const handleChange = (e, envCode) => {
    const object = envSetting.find((e) => e.code === envCode);
    object[e.target.name] = e.target.value;
    setEnvSetting([...envSetting]);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const onClose = () => {
    setEnvSetting(nameEnv);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    const values = envSetting;
    dispatch(updateEnvUI(values));
    setIsEditing(false);
  };

  const checkValue = (key, statusValue) => {
    if (key === 'footerNav') {
      switch (statusValue) {
        case 2:
          return 'Style 2';
        case 3:
          return 'Style 3';
        case 4:
          return 'Style 4';
        case 9:
          return 'Style 9';
        default:
          return 'Style 1';
      }
    }

    if (key === 'displayPageAfterLogin') {
      return pathDisplayPageAfterLogin(statusValue);
    }

    if (
      [
        'homeResultGender',
        'searchResultGender',
        'homeResultGenderApp',
        'searchResultGenderApp',
      ].includes(key)
    ) {
      if (statusValue === 0) {
        return 'Male and Female';
      }
      return 'Opposite';
    } else if (
      [
        'homeSortType',
        'searchSortType',
        'searchSortTypeApp',
        'homeSortTypeApp',
      ].includes(key)
    ) {
      switch (statusValue) {
        case 0:
          return 'image + login';
        case 1:
          return 'image + regist';
        case 2:
          return 'login';
        case 3:
          return 'regist';
        default:
          break;
      }
    } else if (['chatRoomAge', 'chatRoomAgeApp'].includes(key)) {
      if (statusValue === 0) {
        return 'free';
      }
      return 'authenticated user';
    }
    if (['homeResultBlockuser', 'searchResultBlockuser'].includes(key)) {
      if (statusValue === 0) {
        return 'off';
      }

      return 'on';
    }

    if (statusValue === 0) {
      return 'on';
    }

    return 'off';
  };

  function RenderDBDataField(key, statusValue) {
    if (isEditing)
      return (
        <input
          value={statusValue}
          name="status"
          onChange={(e) => handleChange(e, key)}
        />
      );
    return statusValue;
  }

  const renderEditableField = (item, envPropKey, isEditing) => {
    const currentValue = item[envPropKey];
    return (
      <TableCell>
        {isEditing ? (
          <input
            defaultValue={currentValue}
            name={envPropKey}
            onChange={(e) => handleChange(e, item.code)}
          />
        ) : (
          currentValue
        )}
      </TableCell>
    );
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center' }}
        className="main-content-custom"
      >
        <Card style={{ width: '100%' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell title="説明-WEB" style={{ width: '40%' }}>
                  説明-WEB
                </TableCell>
                <TableCell title="DATA名" style={{ width: '20%' }}>
                  DATA名
                </TableCell>
                <TableCell title="設定内容" style={{ width: '20%' }}>
                  設定内容
                </TableCell>
                <TableCell title="DBdata">DBdata</TableCell>
                <TableCell title="DBdata">Order</TableCell>
              </TableRow>
              {envSetting.map((item, index) => (
                <TableRow key={index}>
                  {renderEditableField(item, 'description', isEditing)}
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{checkValue(item.code, item.status)}</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: 500,
                    }}
                  >
                    {RenderDBDataField(item.code, item.status)}
                  </TableCell>
                  {renderEditableField(item, 'displayOrder', isEditing)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    </MuiThemeProvider>
  );
}

export default EnvUiWebSettingList;
