import { handleActions } from 'redux-actions';
import ScheduleChat from '../Types/scheduleChat';

const initialState = {
  items: [],
  loading: false,
  loadingReceivers: false,
  totalRow: 0,
};

const actions = {
  [ScheduleChat.LOAD_SCHEDULE_CHAT]: (state) => ({
    ...state,
    loading: true,
  }),
  [ScheduleChat.LOAD_SCHEDULE_CHAT_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [ScheduleChat.LOAD_SCHEDULE_CHAT_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
  [ScheduleChat.ESTIMATE_SCHEDLULE_CHAT_RECEIVERS]: (state) => ({
    ...state,
    loadingReceivers: true,
  }),
  [ScheduleChat.ESTIMATE_SCHEDLULE_CHAT_RECEIVERS_SUCCESS]: (state, action) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loadingReceivers: false,
  }),
  [ScheduleChat.ESTIMATE_SCHEDLULE_CHAT_RECEIVERS_FAIL]: (state) => ({
    ...state,
    items: [],
    loadingReceivers: false,
  }),
};
export default handleActions(actions, initialState);
